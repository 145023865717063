import './scss/main.scss';
import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';

interface ComponentInitializer {
    initialize(element: HTMLElement): void;
}

const initializers: Record<string, ComponentInitializer> = {
    datepicker: {
        initialize(element: HTMLInputElement) {
            const initialDate = new Date(element.value);
            element.value = initialDate.toISOString().split('T')[0];
            new AirDatepicker(element, {
                dateFormat: 'yyyy-MM-dd',
                timeFormat: 'HH:mm',
                minDate: new Date(Date.now() + 3600000),
                timepicker: true,
                minutesStep: 5,
                locale: localeEn,
                selectedDates: [initialDate],
                onSelect: ({ date }) => {
                    element.setAttribute('value', Array.isArray(date) ? date[0].toISOString() : date.toISOString());
                },
            } as AirDatepickerOptions);
        }
    },
    copyableText: {
        initialize(element: HTMLElement) {
            element.addEventListener('click', () => {
                const textToCopy = element.textContent || '';
                navigator.clipboard.writeText(textToCopy).then(() => {
                    // Highlight the text
                    const range = document.createRange();
                    range.selectNodeContents(element);
                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);

                    // Create and show alert
                    const alert = document.createElement('div');
                    alert.textContent = 'Copied to clipboard!';
                    alert.style.cssText = `
                        position: absolute;
                        background: #333;
                        color: #fff;
                        padding: 5px 10px;
                        border-radius: 3px;
                        font-size: 14px;
                        top: ${element.offsetTop - 30}px;
                        left: ${element.offsetLeft}px;
                        opacity: 0;
                        transition: opacity 0.3s;
                    `;
                    document.body.appendChild(alert);

                    // Show and hide alert
                    setTimeout(() => {
                        alert.style.opacity = '1';
                        setTimeout(() => {
                            alert.style.opacity = '0';
                            setTimeout(() => {
                                document.body.removeChild(alert);
                            }, 300);
                        }, 2000);
                    }, 0);
                }).catch(err => {
                    console.error('Failed to copy text: ', err);
                });
            });
        }
    }
};

const loadComponents = () => {
    const elements = document.querySelectorAll('[data-gamedraws-component]');
    elements.forEach((element) => {
        const component = element.getAttribute('data-gamedraws-component');
        if (!component) {
            throw new Error(`Component attribute is empty for element: ${element.outerHTML}`);
        }

        const initializer = initializers[component];
        if (!initializer) {
            throw new Error(`No initializer found for component: ${component}`);
        }

        initializer.initialize(element as HTMLElement);
    });
};

const removeNoJsClass = () => {
    const htmlElement = document.querySelector('html');
    htmlElement?.classList.remove('gamedraws-no-js');
};

document.addEventListener('DOMContentLoaded', () => {
    loadComponents();
    removeNoJsClass();
});